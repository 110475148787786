import React, { Component } from 'react';
import './App.css';
import Login from './components/Login.jsx';
import Logout from './components/Logout.jsx';
import Dashboard from './components/Dashboard.jsx';
import UnsafeScriptsWarning from "./components/UnsafeScriptsWarning";
import cookie from 'react-cookies'

class App extends Component {

  state = {
    hasError: false,
    isLoggedIn: false,
    showSpinner: true
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log('some error has occured');
    return { hasError: true };
  }

  componentDidMount() {
  //  this.setState({isLoggedIn:cookie.load('isLoggedIn') })  
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  hideSpinner = () => {
    this.setState({showSpinner: false});
  }

  setLoggedin = (res) => {
    console.log("Set logged in set to true !!!! Profile = " , res.profileObj)
    this.setState({isLoggedIn:true})
    cookie.save('isLoggedIn',true,{ path: '/' })

  }

  setLoggedout = () => {
    console.log("Logging out")
    this.setState({isLoggedIn:false})
    cookie.remove ('isLoggedIn')

  }


  render() {
    if (this.state.hasError) {
      return <UnsafeScriptsWarning />;
    }

    console.log("rendering app. isLoggedIn=" + this.state.isLoggedIn)
    if (this.state.isLoggedIn) {
      return (
        <div>
            <div>
              <Dashboard hideSpinner={this.hideSpinner} showSpinner={this.state.showSpinner} /> 
            </div>
     {/*}        <div>
              <Logout 
                 setLoggedout={this.setLoggedout}/>
      </div> */}
        </div>)
    }
    else 
    {
      return (
        <div className="App">
          <Login 
            state={this.state} 
            setLoggedin={this.setLoggedin}/>
        </div>  
      )
    }
    
  }
}

export default App;
