import React from 'react'
import { Detector } from "react-detect-offline";
import StockRow from './StockRow.jsx'

const StocksList = (props) => {
  var nf = new Intl.NumberFormat()
  var nc =  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0})

    //            <tr className='is-size-6 has-text-weight-bold'><td>Day's Gain:</td><td className={setColor(props.total_change)}><div>${nf.format(props.total_change.toFixed(0))} ({nf.format(props.total_pct.toFixed(2))}%)</div></td><td></td></tr>


 function setColor(val)
  {
    if (val > 0) return "green"
    else if (val === 0) return "grey"
      else return "red"

  }



  return (
    <div className='card column is-one-half' id='stocks_list'>
      <div className='portfolio-summary'>
          <table >
            <tbody>
            <tr className='is-size-3 has-text-weight-bold'><td>Holdings:</td><td>${nf.format(props.total_val.toFixed(0))}</td></tr>
            <tr className='is-size-6 has-text-weight-bold'><td>Day's Gain:</td><td className={setColor(props.total_change)}><div>{nc.format(props.total_change)} ({nf.format(props.total_pct.toFixed(2))}%)</div></td><td></td></tr>   
            <tr className='is-size-6 has-text-weight-bold'><td>Total Gain:</td><td className={setColor(props.total_profit)}>{nc.format(props.total_profit)} ({nf.format(props.total_profit_pct.toFixed(2))}%)</td><td></td></tr>
            </tbody>
        </table>
      </div>
      <div className='card-content'>
         <table className='table is-hoverable is-striped'>
          <tbody>
             { props.stock_order.map((stock_name,index) =>
              {
                let current_stock = props.stocks[stock_name];
                return (
                  <StockRow
                    key={index} stock_name={stock_name}
                    stock_data={current_stock}
                    toggleStockSelection={props.toggleStockSelection}
                  />
                )
              }
            )}
            { props.areStocksLoaded() ? null : <tr><td colSpan='4'>No stocks loaded yet!</td></tr> }
       
          </tbody>
         
        </table>
         <div className='refreshed'> Refreshed : {((props.refreshed) instanceof Date)?props.refreshed.toLocaleTimeString() : ""} </div>
       </div>
    </div>
  );
}

export default StocksList;
