import React from 'react';
import * as bulma from "reactbulma";
import { GoogleLogin } from 'react-google-login';
import { refreshTokenSetup } from '../utils/refreshToken';


 class Login extends React.Component 
 {
    state = {
      ErrorMsg : "",
      clientId : '906875000989-gcqqcc29jtj8ua5n9oji8813jgji13cd.apps.googleusercontent.com'}

    onSuccess = (res) => {

      console.log('Login Success: currentUser:', res.profileObj);
  
      refreshTokenSetup(res);
      this.props.setLoggedin();

    };

    onFailure = (res) => {
      console.log('Login failed: res:', res);
      this.setState({ErrorMsg: `Login failed. Error message: "` + res.error + `". Please try again`} )
    };

    autoLoad =() => {return false}

    render()
    {
      console.log("rendering login")
      return (
        <div className='container has-text-centered'>
        <table>
        <tbody>
          <tr className='container has-text-centered' ><td>Login with Google</td></tr>
          <tr><td>
          <GoogleLogin
            clientId={this.state.clientId}
            buttonText="Login"
            uxMode="redirect"
            onSuccess={this.props.setLoggedin}
            onFailure={this.onFailure}
            isSignedIn={true}
            cookiePolicy={'single_host_origin'}
            style={{ marginTop: '100px' }}
          /></td></tr>
 
          <tr className="has-text-danger"><td>{this.state.ErrorMsg}</td></tr>
          </tbody>
          </table>
        </div>
      );
  }
  }

export default Login;