import React from 'react'

const UnsafeScriptsWarning = props => {
  return (
    <div className='container stocks-loader'>
      <div className='card-header'>
        <div className='card-header-icon'>
          <span className='loader'></span>
        </div>
        <div className='card-header-title'>
          Loading...
        </div>
      </div>
      <div className='card'>
        <div className='card-content'>
          Error connecting. You can keep on waiting or hit the reload button !
          <br /><br/><button onClick={ window.location.reload()}>Reload</button>
        </div>
      </div>
    </div>
  );
}

export default UnsafeScriptsWarning;