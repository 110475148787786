import React from 'react';
import { GoogleLogout } from 'react-google-login';

class Logout extends React.Component 
{
  state = {clientId : '906875000989-gcqqcc29jtj8ua5n9oji8813jgji13cd.apps.googleusercontent.com'}

  render()
  {
    return (
        <GoogleLogout
          clientId={this.state.clientId}
          buttonText="Logout"
          onLogoutSuccess={this.props.setLoggedout}
        />
    );
  } 
} 


export default Logout;