import React from 'react'
import {Line} from 'react-chartjs-2';
import * as zoom from 'chartjs-plugin-zoom'
import { chartJsConfig, chartColors, chartDataset } from '../chartConfig.js'
import { findDOMNode } from 'react-dom';



function setColor(val)
{
  if (val > 0) return "green"
  else if (val === 0) return "grey"
    else return "red"

}

class PriceGraph extends React.Component {

  handleZoomPan = () => {

    this.props.loadAll()
  }

  updateChartwithSeries = (field,index_offset) => {
    let chart = this.refs.chart.chartInstance;

    if (Object.keys(this.props.state.stocks).length === 0)
    {
      chart.data.datasets = [];
      return chart.update();
    }

    Object.keys(this.props.state.stocks).map((stock_name, index) =>
    {
      let current_stock = this.props.state.stocks[stock_name];
      let lbl = stock_name + " " + field;
      lbl = lbl.toUpperCase();
      let chart_dataset = chart.data.datasets.find((dataset) => {
        return dataset.label === lbl
      });

      if(current_stock.is_selected)
      {
        let current_stock = this.props.state.stocks[stock_name];
        if(chart_dataset)
        {
          // only update the data, don't create a new dataset for the graph
          chart_dataset.data = this.getStockValues(current_stock, field);
          chart_dataset.id = index_offset;
        }
        else
        {
          // create a new dataset for graph
          if(current_stock)
          {
            console.log("Creating data set for stock : " + stock_name, ", field: " + field );
          
  //          this.scrollToGraph();
            chart.data.datasets = chart.data.datasets.concat(
              [
                chartDataset(lbl, chartColors[6 + index_offset], index_offset,this.getStockValues(current_stock, field))
              ]
            )
 //           console.log(chart.data)
          }
        }
      }
      else
      {
        if(chart_dataset)
        {
          // remove the dataset from graph
          console.log("Removing chart data from dataset for " + stock_name);
          chart.data.datasets.splice(chart.data.datasets.indexOf(chart_dataset), 1);
        }
      }
      chart.options.scales.yAxes[1].scaleLabel.labelString = 'Volume (% SOI)'
      chart.options.scales.yAxes[0].scaleLabel.labelString = 'Price'
      chart.options.scales.xAxes[0].time.max  = new Date(Date.now())
      chart.options.zoom.onZoom =  this.handleZoomPan
      chart.options.pan.onPan =  this.handleZoomPan
      chart.update();
    })
  }

  
  updateChart = () => {
    this.updateChartwithSeries("value",0)
    this.updateChartwithSeries("wtd_volume",1)


    
  }

  componentDidUpdate = () => {
    this.updateChart();
    
  }

    

  // returns an array of objects, {t: timestamp, y: value}
  getStockValues = (stock,field) =>{
    return stock.history.map((history) => {
   //   console.log(field + " = " + history[field])
      return {t: history.date, y: history[field]};
    })
  }

  formatmk = (val) => {
      var v,s

      var nf = new Intl.NumberFormat()

      if (val > 700000){
          v = val / 1000000 
          s = nf.format(v.toFixed(1)) + "M"
          
          }
        else {
          v = val / 1000 
          s = nf.format(v.toFixed(0)) + "K"
        }
      return s
  }

  formatprice = (val) => {
  
    if (val >= 1)
    {
      var nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2})
    }
    else {
      var nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 3, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 3})
    }

        return nf.format(val) 

}

  resetZoom = () => {
      // this.refs.chart.chartInstance.resetZoom();
      this.props.resetCharts();
 }

  scrollToGraph = () => {
    this.GraphStart.scrollIntoView({ behavior: "smooth" });
  }
  

  renderHeader = (selected_stock) => {

    if (selected_stock === null)
    {
      console.log("No stock selected, skipping render of shorts")
      return ("")
    }
    else
    {
      let stock = this.props.state.stocks[this.props.state.selected_stock];
      var pct_avg_vol = Math.round(this.props.state.stocks[this.props.state.selected_stock].pct_avg_volume *100)
      var volsoi = stock.current_volume / stock.shares_issued * 100
      var nf = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 1})
      var nc =  new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0})
      var nm =  new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 1, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 1})

      return (
        <div>
        <table className="graph-header">
            <tbody>
            <tr><td colSpan="2">{stock.name}</td></tr>
            <tr><td>Current/Purchase Price:</td><td className={setColor(stock.changeamt)}><div> {this.formatprice(stock.current_value)} / {this.formatprice(stock.purchaseprice)} ({stock.changepct> 0 ? "+" : ""}{nf.format(stock.changepct.toFixed(2))}%)</div></td></tr>   
            <tr><td>Volume (#/$/SOI):</td><td className={setColor(pct_avg_vol)}> {this.formatmk(stock.current_volume)} / ${this.formatmk(stock.current_volume*stock.current_value)} / {nf.format((volsoi).toFixed(2))}% ({pct_avg_vol > 0 ? "+" : ""}{nf.format(pct_avg_vol.toFixed(2))}%)</td></tr>
            <tr><td >Profit/Value:</td><td className={setColor(stock.profitpct)} colSpan="2"> {nc.format(stock.profitamt)} / {nc.format(stock.holdings)} ({stock.profitpct > 0 ? "+" : ""}{nf.format(stock.profitpct.toFixed(2))}%)</td></tr>
            </tbody>
       </table>
      </div>

      )
      }

  }


  render() {
    
    return (
       <div className={'card column'}  ref={(el) => { this.GraphStart = el; }}>
       
        {this.renderHeader(this.props.state.selected_stock)}

        <div className='card-content'>
          
          <button className="button is-small is-pulled-right" onClick={this.resetZoom}>Reset</button>
          <Line
            data={{datasets: []}}
            options={chartJsConfig}
            ref='chart'
          />
        </div> 
      </div>
    );
  }
}

export default PriceGraph;