import React from 'react'
import {Line} from 'react-chartjs-2';
import * as zoom from 'chartjs-plugin-zoom'
import { chartJsConfig, chartColors, chartDataset } from '../chartConfig.js'
import { findDOMNode } from 'react-dom';


function setColor(val)
{
  if (val > 0) return "green"
  else if (val === 0) return "grey"
    else return "red"

}

class ShortsGraph extends React.Component {


  handleZoomPan = () => {
    this.props.loadAll()
  }

  updateChartwithSeries = (field,index_offset) => {
    let chart = this.refs.chart.chartInstance;
 //   console.log(chart)

 
    if(Object.keys(this.props.state.stocks).length === 0)
    {
      chart.data.datasets = [];
      return chart.update();
    }

    Object.keys(this.props.state.stocks).map((stock_name, index) =>
    {
      let current_stock = this.props.state.stocks[stock_name];
      let lbl = stock_name + " " + field;
      lbl = lbl.toUpperCase();
      let chart_dataset = chart.data.datasets.find((dataset) => {
        return dataset.label === lbl
      });

      if(current_stock.is_selected)
      {
        let current_stock = this.props.state.stocks[stock_name];
        if(chart_dataset)
        {
          // only update the data, don't create a new dataset for the graph
       

          chart_dataset.data = this.getStockValues(current_stock, field);
          chart_dataset.id = index_offset;
        }
        else
        {
          // create a new dataset for graph
          if(current_stock)
          {
            console.log("Creating data set for stock : " + stock_name, ", field: " + field );
            this.scrollToGraph();
            chart.data.datasets = chart.data.datasets.concat(
              [
                chartDataset(lbl, chartColors[1 + index_offset],index_offset, this.getStockValues(current_stock, field))
              ]
            )
          }
        }
      }
      else
      {
        if(chart_dataset)
        {
          // remove the dataset from graph
          console.log("Removing chart data from dataset for " + stock_name);
          chart.data.datasets.splice(chart.data.datasets.indexOf(chart_dataset), 1);
        }
      }
      
      chart.options.scales.yAxes[1].scaleLabel.labelString = 'Short Sales (% volume)'
      chart.options.scales.yAxes[0].scaleLabel.labelString = 'Short Position (% shares)'
      chart.options.zoom.onZoom =  this.handleZoomPan
      chart.options.pan.onPan =  this.handleZoomPan

      chart.update();
    })
  }

  updateChart = () => {
 
    this.updateChartwithSeries("short_pos",0)
    this.updateChartwithSeries("shorts",1)
    this.updateChartwithSeries("value",2)
    
  }

  componentDidUpdate = () => {
    this.updateChart();

  }

  // returns an array of objects, {t: timestamp, y: value}
  getStockValues = (stock,field) =>{
    return stock.history.map((history) => {
       return {t: history.date, y: history[field]};
    })
  }

  handleZoom()
{
  console.log("Handling zoom")
}

  resetZoom = () => {
    // this.refs.chart.chartInstance.resetZoom();
    this.props.resetCharts();

  }


  scrollToGraph = () => {
    this.GraphStart.scrollIntoView({ behavior: "smooth" });
  }
  
  renderHeader = (selected_stock) => {

    if (selected_stock === null)
    {
      console.log("No stock selected, skipping render of shorts")
      return ("")
    }
    else
    {
      var stock = this.props.state.stocks[this.props.state.selected_stock];
      var pct_avg_shorts = Math.round((stock.current_shorts - stock.avg_shorts) / stock.avg_shorts*100)
      var pct_avg_short_pos = Math.round((stock.current_short_pos - stock.avg_short_pos) / stock.avg_short_pos*100)
     
      var nf = new Intl.NumberFormat('en-US',  {
        style: 'percent',
        currency: 'USD',
        minimumFractionDigits: 1, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 1})
      var nc =  new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 1, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 1})
      stock = this.props.state.stocks[this.props.state.selected_stock];
      return (
        <div>
        <table className="graph-header">
          <tbody>
            <tr><td colSpan="3">{stock.name}</td></tr>
          <tr><td>Short Sales:</td><td className={setColor(pct_avg_shorts)}><div> {nf.format(stock.current_shorts/100)} ({pct_avg_shorts > 0 ? "+" : ""}{pct_avg_shorts.toFixed(1)}%)</div></td></tr>   
          <tr><td>Short Position:</td><td className={setColor(pct_avg_short_pos)}> {nf.format(stock.current_short_pos/100)} ({pct_avg_short_pos> 0 ? "+" : ""}{pct_avg_short_pos.toFixed(1)}%)</td></tr>
          </tbody>
       </table>
      </div>

      )
      }

  }

  render() {

    
    return (
       <div className={'card column'}  ref={(el) => { this.GraphStart = el; }}>
       {this.renderHeader(this.props.state.selected_stock)}
      
        <div className='card-content'>
         
          <button className="button is-small is-pulled-right" onClick={this.resetZoom}>Reset</button>
          <Line
            data={{datasets: []}}
            options={chartJsConfig}
            ref='chart'
          />
        </div> 
      </div>
    );
  }
}

export default ShortsGraph;