import React from 'react'


class StocksLoaderStatus extends React.Component {

reload = () => {
  window.location.reload()
}
  render() 
  {
  if(this.props.connectionError) {
    return (
      <div className='is-medium'>
        <span className='has-text-danger' >No data received from server </span>
        <br />(Come back later? :-))
      </div>
    );
  } else {
    return (
      <div className='tag is-large is-success'>
        <span className='loader'> &nbsp;</span>
        &nbsp; &nbsp;  <button className="button is-success" onClick={this.reload}>Fetching stock data...</button>
      </div>
    );
  }
}
}

export default StocksLoaderStatus;