import React from 'react'
import { Sparklines, SparklinesLine } from 'react-sparklines';
import TimeAgo from 'react-timeago'

class StockRow extends React.Component {

  getStockValueColor = (stock,field) =>{
    //console.log(stock)
    //console.log(field)
    if (stock.history.slice(-1)[0][field]< stock.history.slice(-2)[0][field]){
      return 'red';
    }
    else {
      return 'green';
    }
   
  }

  render() 
  {
//    let history = this.props.stock_data.history;
//   console.log(this.props)
    return (
      <tr className={ this.props.stock_data.is_selected ? 'selected' : null } id={this.props.stock_name} onClick={this.props.toggleStockSelection.bind(this, this.props.stock_name)} >
        <td id='card-cell' ><span className="content">{this.props.stock_name.toUpperCase()}</span>{this.props.stock_data.announcement ? <sup className="news">   News </sup> : ""}{this.props.stock_data.hot ? <sup className="news">   Hot </sup> : ""}<div className='is-size-7 has-text-weight-light'>{this.props.stock_data.name}</div></td>
        <td id='value-cell'> {this.props.stock_data.current_value >= 1 ? this.props.stock_data.current_value.toFixed(2): this.props.stock_data.current_value.toFixed(3)}
        </td>
        <td>
         <div id={this.props.stock_data.changepct > 0 ? "greenbox" : this.props.stock_data.changepct === 0 ? "greybox":"redbox"}> {this.props.stock_data.changepct > 0 ? "+" : ""}{ this.props.stock_data.changepct.toFixed(2)}%</div>
        </td>
      </tr>
    );
  }
}
// Spark line code-----
// <td >
//<Sparklines data={history.map((history) => { return history.shorts})}>
//<SparklinesLine color="blue" />
//</Sparklines>
//</td>


export default StockRow;